/* Start Pagination */

// Counter of result.
function loadCounterResult() {
    if (jQuery('.items-counter').is('div') && jQuery('.items-counter:hidden').is('div') === false) {
        var total_items = jQuery('.list-item:not(:hidden)').length;
        if (total_items === 0) {
            jQuery('.items-counter').show().html('No results');
            return;
        }
        let current_page = location.hash.replace('#', '');
        current_page = (current_page !== "") ? current_page : 'page-1';
        var items_page = jQuery('.list-item').filter('.' + current_page);
        var items_page_count = items_page.length;
        var first_item = last_item = 0;
        // first and last item of current page.
        if (items_page_count !== 0) {
            first_item = jQuery(items_page[0]).attr('class').toString().match(/item\-([0-9]*)/);
            first_item = first_item[1];
            last_item = jQuery(items_page[items_page.length - 1]).attr('class').toString().match(/item\-([0-9]*)/);
            last_item = last_item[1];
        }
        jQuery('.items-counter').show().html(first_item + '-' + last_item + ' results on ' + total_items + ' results');
    }
}

function reorderListResult(item_per_page = 5) {
    // Reset page.
    location.hash = "page-1";

    var items_not_hidden = jQuery('.list-item:not(:hidden)');
    jQuery('.list-item').removeClass(function () {
        let number = jQuery(this).attr('class').toString().match(/item\-[0-9]*/);
        return number != null ? number[0] : "";
    }).removeClass(function () {
        let number = jQuery(this).attr('class').toString().match(/page\-[0-9]*/);
        return number != null ? number[0] : "";
    });

    if (items_not_hidden.length > 0) {
        let counter = 1;
        for (const item of items_not_hidden) {
            jQuery(item).addClass('item-' + counter);
            counter = counter + 1;
        }
    }

    // set pages
    var items_not_hidden_chunked = chunk(items_not_hidden, item_per_page);
    var page_num = 1;
    for (var page of items_not_hidden_chunked) {
        for (var item of page) {
            jQuery(item).addClass("page-" + page_num);
        }
        page_num = page_num + 1;
    }
}

function reloadListItems(nb_items = 20, scrollSpeed = 'slow', activeScroll = true) {
    var filterTags = jQuery('#container-system-element input:checkbox:checked');
    if(filterTags.length > 0){
        jQuery(".panel-body .reset").show();
    }
    reorderListResult(nb_items);
    loadCounterResult();
    loadPagination(null, null, nb_items, scrollSpeed, activeScroll);
}

var paginationLoadItems = function (listItems = '.list-item') {
    let current_page = location.hash.replace('#', '');
    current_page = (current_page !== "") ? current_page : 'page-1';
    jQuery(listItems).css({
        'position': 'absolute',
        'left': "-9999px"
    }).filter('.' + current_page).css({
        'position': 'unset',
        'left': "unset"
    });
};

var Pagination = {

    code: '',

    // --------------------
    // Utility
    // --------------------

    // converting initialize data
    Extend: function (data) {
        data = data || {};
        Pagination.size = data.size || 1;
        Pagination.page = data.page || 1;
        Pagination.step = data.step || 3;
        Pagination.items = data.items || [];
        Pagination.refreshItems = data.refeshFn || function () {
        };
        Pagination.refeshCounter = data.refeshCounter || function () {
        };
        Pagination.scrollSpeed = data.scrollSpeed !== null || data.scrollSpeed !== undefined || data.scrollSpeed !== '' ? data.scrollSpeed  : 'slow';
        Pagination.activeScroll = data.activeScroll !== null || data.activeScroll !== undefined || data.activeScroll !== '' ? data.activeScroll  : true;
    },

    // add pages by number (from [s] to [f])
    Add: function (s, f) {
        for (var i = s; i < f; i++) {
            Pagination.code += '<a>' + i + '</a>';
        }
    },

    // add last page with separator
    Last: function () {
        Pagination.code += '<i>...</i><a>' + Pagination.size + '</a>';
    },

    // add first page with separator
    First: function () {
        Pagination.code += '<a>1</a><i>...</i>';
    },


    // --------------------
    // Handlers
    // --------------------

    // change page
    Click: function (e) {
        e.preventDefault();
        Pagination.page = +this.innerHTML;
        Pagination.Start();
    },

    // previous page
    Prev: function (e) {
        e.preventDefault();
        Pagination.page--;
        if (Pagination.page < 1) {
            Pagination.page = 1;
        }
        Pagination.Start();
    },

    // next page
    Next: function (e) {
        e.preventDefault();
        Pagination.page++;
        if (Pagination.page > Pagination.size) {
            Pagination.page = Pagination.size;
        }
        Pagination.Start();
    },


    // --------------------
    // Script
    // --------------------

    // binding pages
    Bind: function () {
        var a = Pagination.e.getElementsByTagName('a');
        for (var i = 0; i < a.length; i++) {
            if (+a[i].innerHTML === Pagination.page) a[i].className += '  active current';
            a[i].addEventListener('click', Pagination.Click, false);
        }
    },

    // write pagination
    Finish: function () {
        location.hash = "page-" + Pagination.page;
        Pagination.refreshItems();
        Pagination.refeshCounter();
        Pagination.e.innerHTML = Pagination.code;
        Pagination.code = '';
        Pagination.Bind();
        // Todo move out this function
        if(Pagination.activeScroll === true) {
            jQuery('.pagination a').on('click', function () {
                jQuery('html,body').animate({scrollTop: jQuery('.component-list-body').offset().top - 200}, Pagination.scrollSpeed);
            });
        }
    },

    // find pagination type
    Start: function () {
        if (Pagination.size < Pagination.step * 2 + 6) {
            Pagination.Add(1, Pagination.size + 1);
        } else if (Pagination.page < Pagination.step * 2 + 1) {
            Pagination.Add(1, Pagination.step * 2 + 4);
            Pagination.Last();
        } else if (Pagination.page > Pagination.size - Pagination.step * 2) {
            Pagination.First();
            Pagination.Add(Pagination.size - Pagination.step * 2 - 2, Pagination.size + 1);
        } else {
            Pagination.First();
            Pagination.Add(Pagination.page - Pagination.step, Pagination.page + Pagination.step + 1);
            Pagination.Last();
        }
        Pagination.Finish();
    },

    // --------------------
    // Initialization
    // --------------------

    // binding buttons
    Buttons: function (e) {
        var nav = e.getElementsByTagName('a');
        nav[0].addEventListener('click', Pagination.Prev, false);
        nav[1].addEventListener('click', Pagination.Next, false);
    },

    // create skeleton
    Create: function (e) {

        var html = [
            '<a>Previous</a>', // previous button
            '<span></span>',  // pagination container
            '<a>Next</a>'  // next button
        ];

        e.innerHTML = html.join('');
        Pagination.e = e.getElementsByTagName('span')[0];
        Pagination.Buttons(e);
    },

    // init
    Init: function (e, data) {
        Pagination.Extend(data);
        if (Pagination.size > 1) {
            Pagination.Create(e);
            Pagination.Start();
        } else {
            e.innerHTML = '';
            Pagination.refreshItems();
        }
        location.hash = "page-1";
    }
};

/* * * * * * * * * * * * * * * * *
* Init of pagination
* * * * * * * * * * * * * * * * */
var loadPagination = function (element, list, item_per_page = 5, scrollSpeed = 'slow', activeScroll = true) {
    if (element === null || element === undefined) {
        element = document.querySelector('.pagination');
    }
    if (list === null || list === undefined) {
        list = document.querySelectorAll('.list-item');
    }
    // Get also not hidden item.
    list = Object.entries(list).filter(item => item[1].style.display != 'none');
    let current_page = location.hash.replace('#', '').match(/page\-([0-9])*/);
    nbPages = Math.ceil(list.length / item_per_page);
    options = {
        size: nbPages,
        page: current_page != null ? parseInt(current_page[1]) : 1,
        step: 1,
        refeshFn: paginationLoadItems,
        refeshCounter: loadCounterResult,
        scrollSpeed : scrollSpeed,
        activeScroll : activeScroll
    };
    Pagination.Init(element, options);
};

function addFilterTag(container, text, value) {
    var wrapper = document.querySelector(container);
    var tag = document.createElement("div");
    tag.className = "tag tag-" + text;
    tag.setAttribute('data-checkbox', value);
    tag.innerHTML = '<span class="tag-text">' + text + '</span><button type="button" class="close" data-dismiss="alert" aria-label="Close">' +
        '<span aria-hidden="true">&#120;</span></button>';
    tag.addEventListener('click', function () {
        let value = this.getAttribute('data-checkbox');
        jQuery('input[value=' + value + ']').click();
        this.remove();
    });
    wrapper.appendChild(tag);
}

/* End Pagination */

function chunk(array, size) {
    const chunked_arr = [];
    let index = 0;
    while (index < array.length) {
        chunked_arr.push(array.slice(index, size + index));
        index += size;
    }
    return chunked_arr;
}

function ucwords(str, force) {
    str = force ? str.toLowerCase() : str;
    return str.replace(/(\b)([a-zA-Z])/g,
        function (firstLetter) {
            return firstLetter.toUpperCase();
        });
}

function gethomeurl(){
    // get home url
    var WpJsonUrl = document.querySelector('link[rel="https://api.w.org/"]').href
    var homeurl = WpJsonUrl.replace('/wp-json/','');
    return homeurl;
}

// onchange class whitout jquery
function addClassObserver(element, callback) {
    callback = callback || function (event) {
        console.warn('Class Observer : set a custom callback please');
    };

    if (element !== null) {
        var observer = new MutationObserver(callback);

        observer.observe(element, {
            attributes: true,
            attributeFilter: ['class'],
            childList: false,
            characterData: false
        });
    }
}

`function download_software(filename, dataUrl) {
    window.location = dataUrl + filename;
    jQuery('.download-modal').modal('hide');
}`

function launch_installation_video() {
    if (jQuery('body').hasClass('l-isa-studio')) {
        jQuery('#modal-l-isa-studio-installation').fadeIn();
        jQuery('#modal-l-isa-studio-installation .close').on('click', function () {
            jQuery('#modal-l-isa-studio-installation').fadeOut();
        });

    }
}

function add_google_event(category, action, label) {
    if (window.ga && ga.create) {
        var trackers = ga.getAll();
        if (trackers.length > 0) {
            let tracker_name = trackers[0].get('name');
            ga(tracker_name + ".send", 'event', category, action, label);
        }
    }
}

function uet_report_conversion_lisa_free() {
    window.uetq = window.uetq || [];
    window.uetq.push('event', 'Download_L-ISA_Studio_Free', {});
}

/**
 * Determine the mobile operating system.
 */
function getMobileOperatingSystem() {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
        return "Windows Phone";
    }

    if (/android/i.test(userAgent)) {
        return "Android";
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return "iOS";
    }

    return "unknown";
}

jQuery(document).ready(function ($) {
    $(function () {
        // Clean menu links
        $('#menu-main-menu > li.menu-item > a:not(.solutions-menu-item)').attr('href', '#').click(
            function (e) {
                e.preventDefault();
                var menu_item = $(this).parent('li.menu-item');
                if (menu_item.find('.wp-megamenu-sub-menu').length !== 0 &&
                    !menu_item.hasClass('current-menu-parent')) {
                    $('#menu-main-menu > li.menu-item').removeClass('current-menu-parent');
                    menu_item.addClass('current-menu-parent');
                    $('#menu-main-menu > li.menu-item > a').addClass('custom_class_menu_hover');
                } else if (e.target.classList.contains('not-anchor')) {
                    menu_item.removeClass('current-menu-parent');
                    $('#menu-main-menu > li.menu-item > a').removeClass('custom_class_menu_hover');
                }
            }
        );

        $('#menu-main-menu > li.menu-item').hover(
            function () {
                if ($('#menu-main-menu > li.menu-item.current-menu-parent').is('li')) {
                    $('#menu-main-menu > li.menu-item').removeClass('hover-menu-parent');
                    $(this).addClass('hover-menu-parent').children('a');
                }
            }
        );

        $('.wp-megamenu').mouseleave(
            function () {
                window.menuHoverTimeoutId = setTimeout(function () {
                    $('#menu-main-menu > li.menu-item').removeClass('current-menu-parent');
                    $('#menu-main-menu > li.menu-item > a').removeClass('custom_class_menu_hover');
                    $('#menu-main-menu > li.menu-item').removeClass('hover-menu-parent');
                }, '500');
            }
        );

        $('#menu-main-menu > li.menu-item').mouseover(function () {
                if (window.menuHoverTimeoutId != undefined && window.menuHoverTimeoutId != null) {
                    clearTimeout(window.menuHoverTimeoutId);
                }
            }
        );

        // Hide menu when click another place.
        window.addEventListener('click', function (e) {
            const menu = document.querySelector('#menu-main-menu');
            if (!menu.contains(e.target)) {
                $('#menu-main-menu > li.menu-item').removeClass('current-menu-parent');
                $('#menu-main-menu > li.menu-item > a').removeClass('custom_class_menu_hover');
                $('#menu-main-menu > li.menu-item').removeClass('hover-menu-parent');
            }
        });
        // Hide menu when scroll window.
        window.addEventListener('scroll', function () {
            if ($('#menu-main-menu > li.menu-item.current-menu-parent').is('li')) {
                $('#menu-main-menu > li.menu-item').removeClass('current-menu-parent');
                $('#menu-main-menu > li.menu-item > a').removeClass('custom_class_menu_hover');
                $('#menu-main-menu > li.menu-item').removeClass('hover-menu-parent');
            }
        });
    });

    // Behaviors for mobile menu (burger menu)
    $(function () {
        var navbar_mobile = document.getElementById("navbar-mobile");
        document.querySelector('.navbar-toggler.close-nav').addEventListener('click', function () {
            navbar_mobile.classList.remove("showed");
        });
        document.querySelector('.navbar-toggler.open-nav').addEventListener('click', function () {
            navbar_mobile.classList.add("showed");
        });
    });

    /* Change the default behavior of the secondary menu found on the main category pages */

    $(window).scroll(function () {
        if ($(window).width() > 991) {
            $('.category-navigation').addClass('sticky-menu');
            $('#nav-main').hide();
            var scrolled_val = $(document).scrollTop().valueOf();
            if (scrolled_val == 0) {
                $('.category-navigation').removeClass('sticky-menu');
                $('#nav-main').show();
            }
        }
    });

    /* Apply specific class on button when a 'New' tag is used */

    var x = document.getElementsByClassName("tag");
    if (x.length > 0) {  // Check if x is not empty
        for (var i = 0; i < x.length; i++) {
            var tag_value = x[i].getAttribute("data-content");  // This will give you the data attribute
            if (tag_value === "new") {
                var tmp = $(x[i]).closest(".product").attr("id");
                $("#" + tmp + " .discover").addClass("background_gold");
            }
        }
    }

    /* Bootstrap carousel with progress bar (for product category template) */

    // init
    $(function () {
        if ($("body").is(".page-template-main_category, .products-template-software")) {
            var carousels = $('.carousel');
            carousels.each(function (id, carousel) {
                var totalSteps = $(carousel).find('.carousel-item').length;
                var currentIndex = $(carousel).find('.carousel-item.active').index() + 1;
                var firstIndex = currentIndex / currentIndex;
                var formattedNumberfirstIndex = ("0" + firstIndex);
                var formattedNumbertotalSteps = ("0" + totalSteps);
                var progress_first = $(carousel).find("#progress .first");
                var progress_last = $(carousel).find("#progress .last");
                var progress_text = $(carousel).find("#progress .progressbar .text");
                progress_first.prepend(formattedNumberfirstIndex);
                progress_last.prepend(formattedNumbertotalSteps);
                var loadIndex = parseInt(currentIndex) / totalSteps * 100;
                progress_text.css({ width: loadIndex + '%' });
                progress_text.addClass("in_progress");
                // This triggers after each slide change
                $(carousel).on('slid.bs.carousel', function (e) {
                    currentIndex = $(carousel).find('.carousel-item.active').index() + 1;
                    formattedCurrentIndex = ("0" + currentIndex);
                    progress_first.empty().prepend(formattedCurrentIndex);
                    step = $(e.relatedTarget).data('step');
                    percent = (parseInt(step) / totalSteps) * 100;
                    progress_text.css({ width: percent + '%' });
                });
            });
        }
    });

    //Bootstrap carousel with progress bar

    $(function () {
        if ($("body").hasClass("single-products") || $("body").hasClass("single-courses")) {

            var arraySectionWithProgessBar = [".related-trainings", ".related-products-container"];

            $.each(arraySectionWithProgessBar, function (i, val) {

                if (val.length) {

                    var totalIndex = $(val + " .carousel-inner .carousel-item").length;
                    var formattedNumbertotalIndex = ("0" + totalIndex);
                    $(val + " #progress .last").prepend(formattedNumbertotalIndex);

                    // init of the first slide before any events

                    var currentIndex = $(val + " .carousel-item.active").index();
                    var formattedCurrentIndex = ("0" + currentIndex);
                    var formattedNumbercurrentIndex = ("0" + currentIndex);
                    $(val + " #progress .first").prepend(formattedCurrentIndex);

                    var loadIndex = parseInt(currentIndex) / totalIndex * 100;
                    $(val + " #progress .progressbar .text").css({ width: loadIndex + '%' });
                    $(val + " #progress .progressbar .text").addClass("in_progress");

                    $(val + " .carousel").on('slid.bs.carousel', function (e) {
                        currentIndex = $(val + " .carousel-item.active").index();
                        var loadIndex = parseInt(currentIndex) / totalIndex * 100;
                        $(val + " #progress .progressbar .text").css({ width: loadIndex + '%' });
                        $(val + " #progress .progressbar .text").addClass("in_progress");
                        formattedCurrentIndex = ("0" + currentIndex);
                        $(val + " #progress .first").empty().prepend(formattedCurrentIndex);
                        step = $(e.relatedTarget).data('step');
                        percent = (parseInt(step) / totalIndex) * 100;
                        $(val + " #progress .progressbar .text").css({ width: percent + '%' });
                    });

                }
            });
        }
    });

    // anchor effect
    $('a[href*="#"]:not(.menu-item):not(.carousel-control):not(.breadcrumb_parent_link):not(.navigation-product .section .anchor):not(.not-anchor):not(.dashboard-urls):not(.subsidiary-nav-link):not(a.get-soundvision)').click(function (e) {
        e.preventDefault();
        var aid = $(this).attr("href");
        $('html,body').animate({ scrollTop: $(aid).offset().top - 100 }, 'slow');
    });


    // Menu sticky page main category
    $('a.anchor').bind('click', function (e) {
        e.preventDefault();
        var target = $(this).attr("href");
        $('html, body').stop().animate({
            scrollTop: $(target).offset().top
        }, 600, function () {
            location.hash = target;
        });
        return false;
    });

    $(window).scroll(function () {
        var scrollDistance = $(window).scrollTop() - 600;
        $('#promote-product-container .product').each(function (i) {
            if ($(this).position().top <= scrollDistance) {
                $('.navigation-product .section a.anchor').removeClass('active');
                $('.navigation-product .section a.anchor').eq(i).addClass('active');
            }
        });
    }).scroll();

    //Popup share
    $(".copy-clipboard").on("click", function () {
        var copyText = document.getElementById("link-to-share");
        copyText.select();
        document.execCommand("copy");
    });

    // Menu product navigation
    $(document).on('click', '.nav-category_product .product-menu-item a', function (e) {

        let data_item = $(this).data('item');
        var array_item_not_allowed = ["downloads", "download-center"];

        if ($.inArray(data_item, array_item_not_allowed) < 0) {

            // we delete the active class on all the menu items
            $('li.product-menu-item .nav-main-link.active').removeClass("active");


            // the current menu is the active menu (all if menu duplicated - software page)
            $('li.product-menu-item .nav-main-link[data-item="' + data_item + '"]').addClass("active");

            localStorage.setItem('lastTab', $(this).attr('data-item'));

            var location = window.location.pathname;
            localStorage.setItem('location', location);

            // we retrieve the attribute of the current item
            var item_current = $(this).attr('data-item');

            // we retrieve in a table all the attributes of the menu concerned
            var array_items = [];
            $('.nav-main-link').each(function () {
                var item_nav = $(this).data('item');
                array_items.push(item_nav);
            });

            // we browse the table to hide the extra divs
            $.each(array_items, function (index, value) {
                if (value == item_current) {
                    $("#" + value).removeClass("hidden");
                    $("body").addClass(item_current); // In case the css is different depending on the tabs
                } else {
                    $("#" + value).addClass("hidden");
                    $("body").removeClass(value);
                }
            });
        }
    })

    $(function () {
        // Go to the latest tab, if it exists:

        var lastLocation = localStorage.getItem('location');
        var currentlyLocation = window.location.pathname;

        // To be sure to retrieve the value of the current page
        if (lastLocation == currentlyLocation) {

            var lastTab = localStorage.getItem('lastTab');
            if (lastTab) {
                $('.nav-category_product .product-menu-item a').removeClass('active');
                $('[data-item="' + lastTab + '"]').addClass("active");


                // we retrieve in a table all the attributes of the menu concerned
                var array_items = [];
                $('.nav-main-link').each(function () {
                    var item_nav = $(this).data('item');
                    array_items.push(item_nav);
                });

                // we browse the table to hide the extra divs
                $.each(array_items, function (index, value) {
                    if (value == lastTab) {
                        $("#" + value).removeClass("hidden");
                        $("body").addClass(lastTab); // In case the css is different depending on the tabs
                    } else {
                        $("#" + value).addClass("hidden");
                    }
                });

            }
        }
    });

    function initCustomSelect(mode = 'async'){
        var selects = document.querySelectorAll('.my-select-wrapper');
        if (selects !== null) {
            for(const select of selects) {
                select.addEventListener('click', function (e) {
                    if (e.target.classList.contains('my-select__trigger') ||
                        e.target.closest('.my-select__trigger') !== null) {
                        e.stopImmediatePropagation();
                        current_selector = this.querySelector('.my-select');
                        if(current_selector.classList.contains('open')){
                            this.querySelector('.my-select').classList.remove('open');
                        } else {
                            this.querySelector('.my-select').classList.add('open');
                        }
                    }
                    //this.querySelector('.my-select').classList.add('open');
                }, true) // true to prevent conflict with option click
                for (const option of document.querySelectorAll(".custom-option")) {
                    option.addEventListener('click', function (e) {
                        if (!this.classList.contains('selected')) {
                            document.querySelectorAll('.my-select').forEach(function(element){
                                element.classList.remove('open');
                            });
                            if(mode === "async") {
                                var selected = this.parentNode.querySelector('.custom-option.selected');
                                if(selected !== null) {
                                    selected.classList.remove('selected');
                                }
                                this.classList.add('selected');
                                this.closest('.my-select').querySelector('.my-select__trigger span').innerHTML = this.innerHTML;
                            }
                            // Sync value for all selects in a page.
                            if(mode === "sync") {
                                var value = this.getAttribute('data-value');
                                var all_options = document.querySelectorAll('.my-select-wrapper .custom-options .custom-option');
                                for (const option of all_options){
                                    option.classList.remove('selected');
                                    if(option.getAttribute('data-value') === value){
                                        option.classList.add('selected');
                                        option.closest('.my-select').querySelector('.my-select__trigger span').innerHTML = this.innerHTML;
                                    }
                                }
                            }
                        } else {
                            this.classList.remove('selected');
                        }
                    })
                }
                window.addEventListener('click', function (e) {
                    const select = document.querySelector('.my-select');
                    if (!select.contains(e.target)) {
                        select.classList.remove('open');
                    }
                })
            }
        }
    }

    /*********** Mobile menu navigation **************/
    $(function () {
        // Custom tabs navigation
        $(document).on('click', '.select-navigation .link', function (e) {
            var current_link = $(this).find('a');
            var all_links = $(this).parents('.select-navigation').find('.link a');

            // we delete the active class on all the menu items
            all_links.each(function () {
                $(this).removeClass("active");
            });

            // the current menu is the active menu
            $(current_link).addClass("active");

            localStorage.setItem('lastTab', $(current_link).attr('data-item'));

            var location = window.location.pathname;
            localStorage.setItem('location', location);


            // we retrieve the attribute of the current item
            var item_current = $(current_link).attr('data-item');
            // we retrieve in a table all the attributes of the menu concerned
            var array_items = [];
            all_links.each(function () {
                var item_nav = $(this).data('item');
                array_items.push(item_nav);
            });

            // we browse the table to hide the extra divs
            $.each(array_items, function (index, value) {
                if (value == item_current) {
                    $("#" + value).removeClass("hidden");
                    $("body").addClass(item_current); // In case the css is different depending on the tabs
                } else {
                    $("#" + value).addClass("hidden");
                    $("body").removeClass(value);
                }
            });

        });
        /*	$(document).on('click', '.select-navigation .link', function (e) {
                $(this).find('a').click();
            });*/
    });

    // Page products ans software in first time
    addClassObserver(document.body, () => { initCustomSelect('sync') });
    /******************************************************/

    // For sticky menu on full spec product
    $(window).scroll(function () {
        if ($('#full-spec').css('display') != 'none') {
            var scrollDistance = $(window).scrollTop();
            $('#container-full-spec .part-spec').each(function (i) {
                if ($(this).position().top <= scrollDistance) {
                    $('.navigation-full-spec .section a.anchor').removeClass('active');
                    $('.navigation-full-spec .section a.anchor').eq(i).addClass('active');
                }
            });
        }
    }).scroll();

    $(function () {

        // Management of Customer Stories pages

        if ($("body").hasClass("single-customer-stories")) {

            var array_headings = [];

            $(".article-body h1, .article-body h2").each(function (index) {
                var heading = $(this);

                // Create automatically ids and storage of elements in an array

                var heading_id = heading.text().toLowerCase().trim().replace(/\s/g, "-");
                heading.attr("id", heading_id); // Create automatically ids
                var heading_label = heading.text().trim();
                array_headings[index] = [heading_label, heading_id];

            });

            // Floating menu management

            if (array_headings.length === 0) {

                $("#navigation-customer-stories").hide();

            } else {

                $("#navigation-customer-stories").empty();
                $.each(array_headings, function (key, value) {
                    // Adding element on the menu with the array values
                    $("#navigation-customer-stories").append("<div class='section'><a href='#" + value[1] + "' class='anchor'>" + value[0] + "</a></div>");
                });


                // Menu sticky page main category
                $('a.anchor').bind('click', function (e) {
                    e.preventDefault();
                    var target = $(this).attr("href");
                    $('html, body').stop().animate({
                        scrollTop: $(target).offset().top
                    }, 300, function () {
                        location.hash = target;
                    });
                    return false;
                });

                $(window).scroll(function () {
                    var scrollDistance = $(window).scrollTop() - 750;
                    $('.article-body .wp-block-group').each(function (i) {
                        if ($(this).position().top <= scrollDistance) {
                            $('.navigation-customer-stories .section a.anchor').removeClass('active');
                            $('.navigation-customer-stories .section a.anchor').eq(i).addClass('active');
                        }
                    });
                }).scroll();

            }
        }
    });

    /******************** Page product system elements *************************/
    function resetFilterTags() {
        $("#container-system-element .component-list-header .tags").html("");
        $(".panel-body .reset").hide();
    }

    // For panel on full-spec
    $("#container-system-element .panel-item").change(function (e) {
        resetFilterTags();
        $("#container-system-element .component").hide();

        // we store all item checked
        var array_item_checked = [];
        $('#container-system-element input:checkbox:checked').each(function () {
            let label = $(this).parent('.input-section').find('label').html().toString().trim();
            array_item_checked.push([$(this).val(), label]);
        });

        // is array empty ?
        if (array_item_checked.length === 0) {
            $("#container-system-element .component").show();
            reloadListItems()
        } else {
            $.each(array_item_checked, function (key, value) {
                $("#container-system-element .component." + value[0]).show();
                addFilterTag("#container-system-element .component-list-header .tags", value[1], value[0]);
            });
            reloadListItems();
        }
    });

    $(".panel-body .reset").on("click", function (e) {
        e.preventDefault();
        $('#container-system-element .form input').removeAttr('checked');
        $("#container-system-element .component").show();
        reloadListItems();
        resetFilterTags();
    });

    // reload when finish display holded tab
    var system_elements = document.getElementById('system-elements');
    if (system_elements) {
        addClassObserver(system_elements, function (event) {
            reloadListItems();
        });
    }

    /****************************** For mobile carousel *********************************/
    if ($(".mobile_carousel .glider").is('div')) {

        var slide_width = parseInt($(".mobile_carousel .glider").attr('data-slide-width')) || 330;
        var slide_to_show = parseInt($(".mobile_carousel .glider").attr('data-slide-show')) || 3.5;

        new Glider(document.querySelector('.glider'), {
            slidesToScroll: 1,
            slidesToShow: slide_to_show,
            draggable: true,
            itemWidth: slide_width,
            exactWidth: true,
            arrows: {
                prev: '.glider-prev',
                next: '.glider-next'
            }
        });
    }




    // Menu universe management

    $('#our-universes-button').on('click', function () {
        $("body").toggleClass('not-scroll');
        $(this).toggleClass('clicked');
        $("#panel-universes").toggleClass('visible');
    });



    $('#articles').on('click', '#load-older-posts a', function (e) {

        // prevent new page load
        e.preventDefault();

        // store next page number
        var next_page = $(this).attr('href');

        // remove older posts button from DOM
        $(this).parent().remove();

        // Show the message
        $(".loading").show();

        // ajax older posts below existing posts
        $('#articles').append(
            $('<div />').load(next_page + ' #articles', function () {
                $(".loading").hide();
            })
        );


    });


    // Management of services menu floating

    $(function () {

        // Management of services pages

        if ($("body").hasClass("single-services")) {

            var array_headings = [];

            $(".article-body h1, .article-body h2, .article-body h3").each(function (index) {
                var heading = $(this);

                // Create automatically ids and storage of elements in an array

                var heading_id = heading.text().toLowerCase().trim().replace(/\s/g, "-").replace(/[.,\/#!?$%\^&\*;:{}=\_`~()]/g, "");
                heading.attr("id", heading_id); // Create automatically ids
                var heading_label = heading.text().trim();
                array_headings[index] = [heading_label, heading_id];

            });

            // Floating menu management

            if (array_headings.length === 0) {

                $("#navigation-services").hide();

            } else {

                $("#navigation-services").empty();
                $.each(array_headings, function (key, value) {
                    // Adding element on the menu with the array values
                    $("#navigation-services").append("<div class='section'><a href='#" + value[1] + "' class='anchor'>" + value[0] + "</a></div>");
                });


                // Menu sticky page main category
                $('a.anchor').bind('click', function (e) {
                    e.preventDefault();
                    var target = $(this).attr("href");
                    $('html, body').stop().animate({
                        scrollTop: $(target).offset().top
                    }, 300, function () {
                        location.hash = target;
                    });
                    return false;
                });

                $(window).scroll(function () {
                    var scrollDistance = $(window).scrollTop() - 750;
                    $('.article-body .wp-block-group').each(function (i) {
                        if ($(this).position().top <= scrollDistance) {
                            $('.navigation-services .section a.anchor').removeClass('active');
                            $('.navigation-services .section a.anchor').eq(i).addClass('active');
                        }
                    });
                }).scroll();

            }

            $("#navigation-services").append("<div class='button-contact'><a class='anchor' href='#services-contact'><div class='request-support'>Request support</div></a></div>");
        }
    });

    // Management of courses menu floating

    $(function () {

        // Management of courses pages

        if ($("body").hasClass("single-courses")) {

            var array_headings = [];

            $(".article-body h1, .article-body h2, .article-body h3").each(function (index) {
                var heading = $(this);

                // Create automatically ids and storage of elements in an array

                var heading_id = heading.text().toLowerCase().trim().replace(/\s/g, "-").replace(/[.,\/#!?$%\^&\*;:{}=\_`~()]/g, "");
                heading.attr("id", heading_id); // Create automatically ids
                var heading_label = heading.text().trim();
                array_headings[index] = [heading_label, heading_id];

            });

            // Floating menu management

            if (array_headings.length === 0) {

                $("#navigation-courses").hide();

            } else {

                $("#navigation-courses").empty();
                $.each(array_headings, function (key, value) {
                    // Adding element on the menu with the array values
                    $("#navigation-courses").append("<div class='section'><a href='#" + value[1] + "' class='anchor'>" + value[0] + "</a></div>");
                });


                // Menu sticky page main category
                $('a.anchor').bind('click', function (e) {
                    e.preventDefault();
                    var target = $(this).attr("href");
                    $('html, body').stop().animate({
                        scrollTop: $(target).offset().top
                    }, 300, function () {
                        location.hash = target;
                    });
                    return false;
                });

                $(window).scroll(function () {
                    var scrollDistance = $(window).scrollTop() - 750;
                    $('.article-body .wp-block-group').each(function (i) {
                        if ($(this).position().top <= scrollDistance) {
                            $('.navigation-courses .section a.anchor').removeClass('active');
                            $('.navigation-courses .section a.anchor').eq(i).addClass('active');
                        }
                    });
                }).scroll();

            }

            $("#navigation-courses").append("<div class='button-contact'><a class='anchor' href='#courses-contact'><div class='request-support'>Request support</div></a></div>");
        }
    });

    // Slider fractional for about us pages

    if ($('figure').hasClass('glider-contain')) {
        $('.glider-contain').append('<button aria-label="Previous" class="glider-prev">«</button>');
        $('.glider-contain').append('<button aria-label="Next" class="glider-next">»</button>');

        // slideshow for About pages
        window.addEventListener('load', function () {
            new Glider(document.querySelector('.glider-contain .blocks-gallery-grid'), {
                slidesToScroll: 1,
                slidesToShow: 2,
                arrows: {
                    prev: '.glider-prev',
                    next: '.glider-next'
                },
            })
        });
    };


    $(function () {
        function timeLineMargin(currentCarousel, nextIndex = 0) {
            let timeLineWidth = currentCarousel.find('.timeline-indicators').innerWidth() / 2;
            let activeWidth = currentCarousel.find('.timeline-indicators li.active').width();
            let first = currentCarousel.find('.timeline-indicators li:first-child');
            let marginStep = (timeLineWidth / 3);
            var margin = timeLineWidth - (marginStep * nextIndex);
            if (nextIndex === 0) {
                margin -= activeWidth;
            } else {
                margin -= activeWidth / 2;
            }
            first.css('margin-left', margin);
        }

        var carousel = $('.about-us-history-carousel');
        carousel.carousel('pause');
        timeLineMargin(carousel);
        carousel.on('slide.bs.carousel', function (e) {
            var currentCarousel = $(this).closest('.about-us-history-carousel');
            var nbElement = currentCarousel.find('.carousel-item').length - 1;
            timeLineMargin(currentCarousel, e.to);
            $(this).find('.carousel-control').show();
            if (e.to === 0) {
                $(this).find('.carousel-control-prev').hide();
            } else if (e.to === nbElement) {
                $(this).find('.carousel-control-next').hide();
            }
        });
    });


    $(function () {

        if ($("body").is(".page-template-education_category")) {

            // Adding specifical css classe for learning path when development skill is indicated
            var x = document.getElementsByClassName("label");
            var i;
            for (i = 0; i < x.length; i++) {
                tag_value = x[i].getAttribute("data-content");  //this will give you the data attribute
                if (tag_value == "skills-development") {
                    tmp = $(x[i]).closest(".path").addClass("background_black");
                }
            };

            // Sorting courses (by default when a learning-path is not clicked)

            $("#select-courses").change(function () {
                $(".tile-courses").hide();
                var value_selected = $(this).val();
                if (value_selected == "all-path") {
                    $(".tile-courses").show();
                } else {
                    $('.label-type').each(function () {
                        if ($(this).text() == value_selected) {
                            $(this).parents(".tile-courses").show();
                        }
                    })

                }
                // Modification of the result number
                var counter = $('.tile-courses:not([style*="display: none"])').length;
                $('.number-result #number').text(counter);
            });


            // When learning path is clicked (override the default behaviour)

            $(document).on('click', '.link-path', function (e) {
                var name = $(this).find(".name").text();
                localStorage.setItem('learning-path', name);
            });


            var learningpath = localStorage.getItem('learning-path');
            if (learningpath !== null) {
                $("#select-courses").val(learningpath);
                $(".tile-courses").hide();
                $('.label-type').each(function () {
                    if ($(this).text() == learningpath) {
                        $(this).parents(".tile-courses").show();
                    }
                })

                // Modification of the result number
                var counter = $('.tile-courses:not([style*="display: none"])').length;
                $('.number-result #number').text(counter);

                // Reset the value on the local storage
                localStorage.removeItem('learning-path');

            }

        }

    });

    $(function () {
        if ($("body").hasClass("hire-a-certified-engineer") ||
            $("body").hasClass("find-a-certified-engineer")) {
            reloadListItems(10, 0);

            $('#country').on('change', function () {
                var value = $(this).val().trim();
                $('.component-list-body .list-item').show();
                if (value !== 'all') {
                    for (item of $('.component-list-body .list-item')) {
                        if ($(item).find('.country').html().trim() !== value) {
                            $(item).hide();
                        }
                    }
                }
                reloadListItems(10, 0);
            });
        }
    });

    $(function () {
        $('.download-modal .modal-body .form a.download-link').on('click', function (e) {
            e.preventDefault();
            let target = $(this).attr('target');
            if (target === '_blank') {
                window.open($(this).attr('href'));
            } else {
                window.location = $(this).attr('href');
            }
            $('.download-modal').modal('hide')
        });

        $('.download-modal .modal-body .form .operating-system select').on('change', function (e) {
            var os = $(this).val();
            switch (os) {
                case 'macOS':
                    $('.download-modal .modal-body div.version span.version').html($('.download-modal .modal-body #version_mac').val());
                    $('.download-modal .modal-body div.version span.release').html($('.download-modal .modal-body #release_date_mac').val());
                    break;
                default:
                case 'Windows':
                    $('.download-modal .modal-body div.version span.version').html($('.download-modal .modal-body #version_win').val());
                    $('.download-modal .modal-body div.version span.release').html($('.download-modal .modal-body #release_date_win').val());
                    break;
            }
        });
        $('.beta-versions .modal-body .form .operating-system select').on('change', function (e) {
            var os = $(this).val();
            switch (os) {
                case 'macOS':
                    $('.beta-versions .modal-body div.version span.version').html($('.beta-versions .modal-body #beta_version_mac').val());
                    $('.beta-versions .modal-body div.version span.release').html($('.beta-versions .modal-body #release_date_mac').val());
                    break;
                default:
                case 'Windows':
                    $('.beta-versions .modal-body div.version span.version').html($('.beta-versions .modal-body #beta_version_win').val());
                    $('.beta-versions .modal-body div.version span.release').html($('.beta-versions .modal-body #release_date_win').val());
                    break;
            }
        });
    });

    // Filters management on search page
    if ($("body").hasClass("search")) {
        $(function () {
            // Get all CPT
            var array_classes = [];
            $('.search-item-container').each(function (i) {
                class_name = $(this).attr('class').split(' ')[1]; // To exclude the search-item-container class
                if ($.inArray(class_name, array_classes) == -1) { // not in array
                    array_classes.push(class_name);
                }
            });
            // Count CPT
            $.each(array_classes, function (index, value) {
                var num_items = $('.section-search-results').children("." + value).length;
                switch (value) {
                    case "hot-news":
                        custom_value = "news";
                        break;
                    case "press-release" :
                        custom_value = "press releases";
                        break;
                    case "story":
                        custom_value = "stories";
                        break;
                    case "customer-stories":
                        custom_value = "customer stories";
                        break;
                    case "products":
                        custom_value = "product";
                        break;
                    case "page":
                        custom_value = "others";
                        break;
                    default:
                        custom_value = value;
                }
                $("#cpt-list").append("<div class='item-cpt'><a class='item nav-main-link not-anchor' href='#' data-target=" + value + ">" + custom_value + " (" + num_items + ") " + "</a></div>");
            });

        });
        // At the beginning, only results of the first item is displayed
        $(function () {
            $(".search-item-container").hide();
            var first_menu = $("#cpt-list .item-cpt a.item").first().data("target");
            $("." + first_menu).show();
            // the first menu is the active item
            $("#cpt-list .item-cpt a.item").first().addClass("current-menu-item-cpt");
        });
        // Click management
        $(function () {
            $('.item-cpt a.item').on('click', function (e) {
                e.preventDefault();
                $(".search-item-container").hide();
                target_cpt = $(this).attr('data-target');
                $("." + target_cpt).show();
                // the current item is the active item
                $(".item-cpt a.item").removeClass("current-menu-item-cpt");
                $(this).addClass("current-menu-item-cpt");
            });
        });
    };

    $(function () {
        $('div.hire-a-certified-engineer-container .component-list-body .list-item a.email').on('click', function (e) {
            let kse_id = $(this).closest('.list-item').attr('data-kse');
            let kse_name = $(this).closest('.list-item').find('.name').html();
            let kse_country = $(this).closest('.list-item').find('.country').html();
            let modal = $('#modal-contact-kse');
            modal.find('.modal-title .kse_name').html(kse_name + " (" + kse_country + ")");
            modal.find('.modal-body .form form .wpforms-field-container input[type="hidden"]:last').val(kse_id);
            modal.modal('show');
        });
        $('.download-modal .modal-body .form form').on('submit', function (e) {
            var modal = $('#modal-contact-kse');
            let button = $(this).find('button[type="submit"][value="wpforms-submit"]');
            var sending = setInterval(function () {
                if (button.html() === "Send") {
                    clearInterval(sending);
                    modal.find('.modal-body .notification').css('display', '');
                    modal.find('.modal-body .form').css('display', 'none');
                    setTimeout(function () {
                        modal.modal('hide');
                    }, 10000);
                }
            }, 1000);

        });

        $('#modal-contact-kse').on('hidden.bs.modal', function (e) {
            $(this).find('.modal-body form .wpforms-field-container input:not([type="hidden"]), ' +
                '.modal-body form .wpforms-field-container textarea, ' +
                '.modal-body form .wpforms-field-container select').val('');
            $(this).find('.modal-body form input.wpforms-token').remove();
            $(this).find('.modal-body .notification').css('display', 'none');
            $(this).find('.modal-body .form').css('display', '');
        });
    });

    // Add button cancel on the contact form

    $(function () {
        $(".contact-container-floating").click(function () {
            if ($('#button-cancel-custom').length == 0) {
                $('#modal-contact .wpforms-submit-container button.wpforms-submit').after('<button type="button" id="button-cancel-custom" class="wpforms-button-cancel" data-dismiss="modal">Cancel</button>');
            }
        });
    });

    //venue database filtering

    function get_number_results(inner) {
        var counter = $(inner + ':not([style*="display: none"])').length;
        var counter_final = counter + " results";
        $('#counter_venue .counter_venue_indication').text(counter_final);
    }

    //get_number_results('.venues-result-containers .venue-result');

    $(function () {
        if ($("body").hasClass("venues-database")) {
            reloadListItems(20, 0);
        }
    });

    // select venue
    $("#countries_venue").change(function () {
        $(".venues-result-containers .venue-result").hide();
        var country_value_selected = $(this).val();
        if (country_value_selected == "all-countries") {
            $(".venues-result-containers .venue-result").fadeIn(500);
        } else {
            $(".venues-result-containers " + "." + country_value_selected).fadeIn(500);
        }
        //get_number_results('.venues-result-containers .venue-result');
        reloadListItems(20, 0);
    });

    // search venue
    $('.form-search .btn').on('click', function (e) {
        e.stopImmediatePropagation();
        $(".venues-result-containers .venue-result").hide();
        var raw_query = $.trim($('.form-search .search-query').val());
        raw_query = raw_query.replace(',', ' ');
        var queries = [];
        queries.push(raw_query.split(','));
        queries.push(raw_query.split(' '));
        queries = queries.flat();
        /***** clean array and remove duplicates ****/
        queries = queries.map(q => q.trim());
        queries = queries.filter(q => q.replace(/[,]*/, ''));
        queries = queries.map(q => q.replace(/[,]*/, ' '));
        queries = queries.map(q => q.trim());
        queries = queries.filter((q, index) => { return queries.indexOf(q) === index; });
        queries = queries.filter(q => {
            if (q == raw_query && q.search(' ') > -1) {
                return false;
            } else {
                return true;
            }
        });
        /**************** end clean ****************/
        if (queries.length === 0) {
            $('div.venue-result').show();
        } else {
            var exists_arr = [];
            var venues = $('div.venue-result');
            for (query of queries) {
                var search_query = new RegExp(query, 'i');
                venues.each(function (index) {
                    var exist = false;
                    exist = exist || $(this).find('.information-result .title-result').html().search(search_query) !== -1;
                    $(this).find('.information-result .tag').each(function () {
                        exist = exist || $(this).html().search(search_query) !== -1;
                    });
                    exists_arr[index] = exists_arr[index] != undefined ? exists_arr[index] && exist : exist;
                });
            }
            exists_arr.forEach(function (venue_result, ind) {
                if (venue_result === true) {
                    $(venues[ind]).fadeIn(500);
                }
            });
        }
        reloadListItems(20, 0);
    });
    $('.form-search').on('submit', function (e) {
        e.preventDefault();
    });

    // Add button for specific modal form
    $(function () {
        if ($('.modal-have-cancel-button').is('div') == true) {
            if ($('.modal-have-cancel-button #button-cancel-custom').length == 0) {
                $('.modal-have-cancel-button .wpforms-submit-container button.wpforms-submit').after('<button type="button" id="button-cancel-custom" class="wpforms-button-cancel" data-dismiss="modal">Cancel</button>');
            }
        }
    });

    /*** Some fonctionalities for KSE application form ***/
    $(function () {
        $(".kse-application-form-rental-agent-button").click(function () {
            var hidden_agent_name = $(".kse-application-form-rental-agent-name:hidden");
            if (hidden_agent_name.length > 0) {
                $(hidden_agent_name[0]).show();
            } else {
                $(this).hide();
            }
        });

        $(".kse-application-form-tour-button").click(function () {
            var hidden_tour_name = $(".kse-application-form-tour:hidden");
            if (hidden_tour_name.length > 0) {
                $(hidden_tour_name[0]).show();
            } else {
                $(this).hide();
            }
        });

        $(".kse-application-form-select-system").change(function () {
            var hidden_select_system = $(".kse-application-form-select-system:hidden");
            var hidden_text_system = $(".kse-application-form-select-textfield:hidden");
            if (hidden_select_system.length > 0) {
                $(hidden_select_system[0]).show();
                $(hidden_text_system[0]).show();
            }
        });

        var dz_message = "<svg viewBox=\"0 0 40 40\" height=\"40\" width=\"40\" xmlns=\"http://www.w3.org/2000/svg\"><g transform=\"matrix(1.6666666666666667,0,0,1.6666666666666667,0,0)\"><path d=\"M23.25,18.75v3a1.5,1.5,0,0,1-1.5,1.5h-3\" fill=\"none\" stroke=\"#AFB3B6\" stroke-linecap=\"round\" stroke-linejoin=\"round\" stroke-width=\"1.5\"></path><path d=\"M18.75.75h3a1.5,1.5,0,0,1,1.5,1.5v3\" fill=\"none\" stroke=\"#AFB3B6\" stroke-linecap=\"round\" stroke-linejoin=\"round\" stroke-width=\"1.5\"></path><path d=\"M.75,5.25v-3A1.5,1.5,0,0,1,2.25.75h3\" fill=\"none\" stroke=\"#AFB3B6\" stroke-linecap=\"round\" stroke-linejoin=\"round\" stroke-width=\"1.5\"></path><path d=\"M5.25,23.25h-3a1.5,1.5,0,0,1-1.5-1.5v-3\" fill=\"none\" stroke=\"#AFB3B6\" stroke-linecap=\"round\" stroke-linejoin=\"round\" stroke-width=\"1.5\"></path><path d=\"M7.875 9.000 A4.125 4.125 0 1 0 16.125 9.000 A4.125 4.125 0 1 0 7.875 9.000 Z\" fill=\"none\" stroke=\"#AFB3B6\" stroke-linecap=\"round\" stroke-linejoin=\"round\" stroke-width=\"1.5\"></path><path d=\"M18.365,19.125a6.751,6.751,0,0,0-12.73,0\" fill=\"none\" stroke=\"#AFB3B6\" stroke-linecap=\"round\" stroke-linejoin=\"round\" stroke-width=\"1.5\"></path></g></svg>";
        dz_message += "<div class='modern-title'>Click or drag your photo.</div>";
        $(".kse-application-form-project-photo-upload .wpforms-uploader .dz-message").html(dz_message);

        $(".kse-application-form-project-xmlp").each(function (index, item) {
            xmlp_message = $(item).find(".wpforms-uploader .dz-message .modern-title").html("xmlp file")
        });

        $(".kse-application-form-project-lses").each(function (index, item) {
            xmlp_message = $(item).find(".wpforms-uploader .dz-message .modern-title").html("nwm file")
        });
    });

    // stop video throw concept when the modal is hide
    $('.modal-throw-concept').on('hide.bs.modal', function (e) {
        setTimeout(function () {
                $('.modal-throw-concept video').attr("src", jQuery(".modal-throw-concept video").attr("src"));
            }, 500
        );
    });
    // prevent video to scrol on the top
    $(".link-video-throw").click(function (e) {
        e.preventDefault();
    });

    // Now playing load more button management
    if ($("body").hasClass("post-type-archive-now-playing")) {

        const now_playing_tiles = $(".tease-now-playing");

        function showHideMoreButton() {
            if (now_playing_tiles.filter('.hit:hidden').length === 0) {
                $("#load-older-now-playing").css('visibility', 'hidden');
            }
        }

        function initPaginationPlayingNow() {
            now_playing_tiles.addClass('hit').slice(3).hide();
            showHideMoreButton();
        }

        function showSliceHidden() {
            now_playing_tiles.filter('.hit:hidden').slice(0, 3).slideDown();
            showHideMoreButton();
        }

        // Init the page
        initPaginationPlayingNow();

        // Filter by types and regions
        $("#select-now-playing,#select-now-playing-region,#select-now-playing-month").change(function () {
            $('.no-results').hide();
            now_playing_tiles.hide().addClass('hit');
            $("#load-older-now-playing").css('visibility', 'visible');

            var type_now_playing_selected = $("#select-now-playing").val(); // type
            var region_now_playing_selected = $("#select-now-playing-region").val(); // region
            var month_now_playing_selected = $("#select-now-playing-month").val(); // month

            // Remove hit for not selected type
            if (type_now_playing_selected !== null && type_now_playing_selected !== "all-types") {
                now_playing_tiles.filter(":not(." + type_now_playing_selected + ")").removeClass('hit');
            }

            // Remove hit for not selected region
            if (region_now_playing_selected !== null && region_now_playing_selected !== "all-regions") {
                now_playing_tiles.filter('.hit:not([data-region= ' + region_now_playing_selected + '])').removeClass('hit');
            }

            // Remove hit for not selected month
            if (month_now_playing_selected !== null && month_now_playing_selected !== "all-months") {
                now_playing_tiles.filter('.hit:not([data-month= ' + month_now_playing_selected + '])').removeClass('hit');
            }


            if (now_playing_tiles.filter(".hit").length > 0) {
                // Display those remaining (the third)
                now_playing_tiles.filter(".hit").slice(0, 3).show();
            } else {
                $('.no-results').show();
                $("#load-older-now-playing").css('visibility', 'hidden');
            }

            showHideMoreButton();

        });

        // When the button "load more" is clicked
        $("body").on('click touchstart', '#load-older-now-playing', function (e) {
            e.preventDefault();
            showSliceHidden();
        });

        // Insertion of regions datas select
        var array_items_region = [];
        now_playing_tiles.each(function () {
            var item_region = $(this).data('region');
            if ($.inArray(item_region, array_items_region) == -1) {
                array_items_region.push(item_region);
            }
        });
        array_items_region.sort();
        $.each(array_items_region, function (index, value) {
            $('#select-now-playing-region').append(`<option value="${value}">${value}</option>`);
        });

    }



    /******************** Documentation center *************************/

    $(function () {

        function getChoice(sParam) {
            var sPageURL = window.location.search.substring(1),
                sURLVariables = sPageURL.split('&'),
                sParameterName,
                i;

            for (i = 0; i < sURLVariables.length; i++) {
                sParameterName = sURLVariables[i].split('=');

                if (sParameterName[0] === sParam) {
                    return typeof sParameterName[1] === undefined ? true : decodeURIComponent(sParameterName[1]);
                }
            }
            return false;
        };

        var choice = getChoice('choice');
        if (choice) {
            if (choice == "all catalog") {
                $('input.search_document').val("All catalog");
            } else {
                $('input.search_document').val(choice);
            }

        }


        if ($("body").hasClass("result-documentation-center")) {
            reloadListItems(20, 0);
        }

        function ResetFilterTagsDocumentationCenter() {
            $("#container-documentation-center .component-list-header .tags").html("");
            $(".panel-body .reset").hide();
            $('.component-list-body .component-list-header .indicator-for').html('');
        }

        //Check for document parameter in the url
        var documentType = getChoice('document');
        if (documentType) {
            $("#" + documentType).attr("checked", documentType.indexOf(documentType) !== -1);
            doDocumentationCenter();
        }

        function doDocumentationCenter() {
            ResetFilterTagsDocumentationCenter();
            $("#container-documentation-center .component").hide();
            // we store all item checked
            var array_item_checked = [];
            $('#container-documentation-center input:checkbox:checked').each(function () {
                let label = $(this).parent('.input-section').find('label').html().toString().trim();
                array_item_checked.push([$(this).val(), label]);
            });

            // is array empty ?
            if (array_item_checked.length === 0) {
                $('.component-list-body .component-list-header .indicator-for').html('');
                $("#container-documentation-center .component").show();
                reloadListItems()
            } else {
                $.each(array_item_checked, function (key, value) {
                    $(".panel-body .reset").show();
                    $("#container-documentation-center .component." + value[0]).show();
                    $('.component-list-body .component-list-header .indicator-for').html(' for : ');
                    addFilterTag("#container-documentation-center .component-list-header .tags", value[1], value[0]);
                });
                reloadListItems();
            }
        }

        // For panel on full-spec
        $("#container-documentation-center .panel-item").change(function (e) {
            doDocumentationCenter();
        });

        $(".panel-body .reset").on("click", function (e) {
            e.preventDefault();
            $('#container-documentation-center .form input').removeAttr('checked');
            $("#container-documentation-center .component").show();
            reloadListItems();
            ResetFilterTagsDocumentationCenter();
        });

        // reload when finish display holded tab
        var documentation_center = document.getElementById('system-elements');
        if (documentation_center) {
            addClassObserver(documentation_center, function (event) {
                reloadListItems();
            });
        }

    });

    $('#youtubePlayer').on('click', function () {
        $('.bg-dim').addClass('activateBg');
        $('#lisaStudioTutorialPlaylist').addClass('d-none');
        $('#youtubePlayer').slideUp();
        $('.embed-container').slideDown();
    });

    $('#wpforms-form-17430').attr('name', 'Download-L-ISA-Studio-Free');

    var currentMobileOS = getMobileOperatingSystem();
    var facebookurl = $('#facebookURL');

    if (currentMobileOS == 'Android') {
        facebookurl.attr('href', 'fb://facewebmodal/f?href=https://www.facebook.com/lacoustics.info/')
    } else if (currentMobileOS == 'iOS') {
        facebookurl.attr('href', 'fb://page/lacoustics.info/')
    } else {
        facebookurl.attr('href', 'https://www.facebook.com/lacoustics.info/')
    }
});

jQuery(document).ready(function ($) {
    if ($('body').hasClass('page-template-sessions-calendar')) {
        var sessions_list = $('.row-list-all-sessions');
        $('select').change(function () {
            runAllFilters();
            updateAllSessionsCount(1);
        });

        function updateAllSessionsCount(onchangeevent = 0) {
            $('.active-month').removeClass('d-none');
            $('.month-container').each(function () {
                var thisid = $(this).attr('id');
                var total_elements = 0;
                total_elements = $("#" + thisid + " .hover-row").length;
                if (onchangeevent == 1) {
                    total_elements_hidden = $("#" + thisid + " .hover-row.d-none").length;
                    total_elements = parseInt(total_elements) - parseInt(total_elements_hidden);
                    if (parseInt(total_elements) == 0) {
                        $('.active-month[data-month="' + thisid + '"]').addClass('d-none');
                        $('.empty-month').addClass('d-none');
                    }
                }
                var sessions_tag;
                if (parseInt(total_elements) > 1) {
                    sessions_tag = ' sessions';
                } else {
                    sessions_tag = ' session';
                }
                $('#sessionsCount' + thisid).html('<p class="month-sessions mb-0">' + total_elements + sessions_tag + '</p>');
                $('#' + thisid + 'SessionsListCount .count-sessions').text(total_elements + sessions_tag);
            });
        }

        function runAllFilters() {
            var list = $(".empty-month, .active-month");
            var single_session = $('.hover-row');
            $(list).addClass('d-none');
            $(single_session).addClass('d-none');

            // var filtered = $(".results-sessions-block .active-month");
            var filtered_single = $(".row-list-all-sessions .hover-row");

            // Get all filter values
            var language_selected = $('#filter-languages').val();
            var course_selected = $('#filter-courses').val();
            var location_selected = $('#filter-locations').val();
            var location_label = $('#filter-locations option:selected').text();

            filtered_single = filtered_single.filter(function () {
                return RegExp(language_selected).test($(this).attr("data-languages")) &&
                    RegExp(course_selected).test($(this).attr("data-courses")) &&
                    RegExp(location_selected).test($(this).attr("data-locations"));
            });

            var available_sessions = filtered_single.length;

            if (available_sessions === 0) {
                $('.results-text').text('Sorry, no results for ' + course_selected + ' in ' + location_label + ' in ' + language_selected);
                $('.no-results').show();
            } else {
                $('.no-results').hide();
                $('.results-text').text('');
                filtered_single.each(function () {
                    var sessions_name;
                    if (filtered_single.length == 1) {
                        sessions_name = 'session';
                    } else {
                        sessions_name = 'sessions'
                    }
                    var session_month = $(this).attr('data-session-month');
                    var month_block = $('div[data-month="' + session_month + '"]');
                    month_block.removeClass('d-none');
                })
            }
            filtered_single.each(function (i) {
                $(this).removeClass('d-none');
            });
        };
        updateAllSessionsCount(0);
        $('.active-month').each(function () {
            var month = $(this).attr('data-month');

            $(this).on('click', function () {

                $('.empty-month, .active-month').fadeOut();
                $('#' + month).removeClass('d-none').fadeIn();
                $('#' + month).attr('data-hide', 'show');
                $('.list-month-title').on('click', function () {
                    $('#' + month).attr('data-hide', 'hide');
                    $('#' + month).fadeOut();
                    $('.active-month').fadeIn();
                });
            });
            $('select').change(function () {
                if ($('#' + month).attr('data-hide') == 'show') {
                    $('.list-month-title').trigger('click');
                    $('#' + month).attr('data-hide', 'hide');
                }
            });
        });

    }

    function switchTabOnHash() {
        var software_hash = location.hash.replace(/^#/, '');
        if (software_hash) {
            $('.nav-category_product .product-menu-item a[data-section="' + software_hash + '"]').trigger('click');
        }
    }

    $(document).ready(switchTabOnHash);
    $(window).on('hashchange', switchTabOnHash);

    $('.feature-desc').on('click', function () {
        $(this).siblings('.description-container').slideToggle();
    });

    // Custom JS for counterfeit

    if ($("body").hasClass("page-template-counterfeit")) {

        $( "button.button" ).click(function() {

            // we prevent the blank inputs
            let possiblecheck = true;
            if ($('form input#search_serial').val() == "") {
                $('form input#search_serial').addClass("error");
                $("#search_serial_error").removeClass("d-none");
                possiblecheck = false;
            }
            if ($('form input#search_product').val() == "") {
                $('form input#search_product').addClass("error");
                $("#search_product_error").removeClass("d-none");
                possiblecheck = false;
            }
            if ($('#search_qrcode').css('display') == 'inline-block') {
                if($('form input#search_qrcode').val() == "") {
                    $('#search_qrcode').addClass("error");
                    $("#search_qrcode_error").removeClass("d-none");
                    possiblecheck = false;
                }
            }
            if (possiblecheck == true) {
                $('#modal-spinner').modal('show');
                // Get values selected
                var serial_number_chosen = $('form input#search_serial').val();
                var product_chosen = $('form input#search_product').val();
                var restit_value_chosen = $('form input#search_qrcode').val();
                // get home url
                var homeurl = gethomeurl();
                var jqxhr = $.getJSON( homeurl + "/wp-content/scripts/counterfeit/GetProductForCounterfeitSection.php?itemNumber=E1083&serial=" + serial_number_chosen + "&restitValue=" + restit_value_chosen, function() {
                })
                    .done(function() {
                        $('#modal-spinner').modal('hide');
                        $('#recognized-product').modal('show');
                    })
                    .fail(function() {
                        $('#modal-spinner').modal('hide');
                        $('#unrecognized-product').modal('show');
                    })
            }
        });
        var availableTags = [
            "CS238",
            "CF112",
            "CF133",
            "RS115",
            "RS138",
            "CS138",
            "MTD115a",
            "MTD118/BE-CO",
            "SB112",
            "ULF246",
            "SOKA",
            "SB6i",
            "LA7.16i",
            "L-ISA PROCESSOR II",
            "DOE2/45/100",
            "SB10i",
            "TECH TOOLCASE II",
            "K3i",
            "KARA IIi",
            "SB18 IIi",
            "LA2Xi",
            "K3",
            "A15i",
            "A10i",
            "KS21i",
            "LA-RAK II AVB",
            "LS10",
            "A10",
            "KARA II",
            "A15",
            "KS21",
            "X4i",
            "L-ISA PROCESSOR",
            "P1",
            "SYVA",
            "SYVA LOW",
            "SYVA SUB",
            "KIVA II",
            "LA-RAK II",
            "LA12X",
            "KS28",
            "X12",
            "X15HiQ",
            "X8",
            "K2",
            "LA4X",
            "ARCS FOCUS",
            "ARCS WIDE",
            "SB18m",
            "SB15m",
            "5XT",
            "ARCS II",
            "SB18i",
            "KARA",
            "KARAi",
            "SB18",
            "12XTi",
            "8XTi",
            "LA-RAK",
            "K1",
            "K1-SB",
            "SB28",
            "LA8",
            "LA4",
            "12XT",
            "8XT",
            "KILO",
            "KIVA",
            "108P",
            "112P",
            "SB15p",
            "KUDO",
            "115XT HiQ",
            "112XT",
            "115XT",
            "LLC112b-st",
            "LLC115b-2w",
            "LLC115b-st",
            "MTD108a",
            "MTD108LLCa",
            "MTD112b",
            "MTD115b",
            "SB118",
            "LA15a",
            "LA17a",
            "LA24a",
            "LA48a",
            "dV-SUB",
            "dV-DOSC",
            "LA15",
            "LA17",
            "LA24",
            "LA48",
            "SB115",
            "RK12 rack and V Legacy accessory standard",
            "115FM",
            "LLC115 FM",
            "DFX24",
            "ROUTING CONTROLLER RC 23",
            "MTD112LLCa",
            "MTD115LLCa",
            "MTD115a",
            "ROUTING CONTROLLER RC 46",
            "CAMCO DX12",
            "CAMCO DX24",
            "ARCS",
            "MTD108",
            "EX115 LLC",
            "MTD112 LLC",
            "EX115",
            "MTD112",
            "EX112",
            "SB115",
            "SB218",
            "CAMCO DL 3000 2P",
            "MTD118",
            "CAMCO DL 1000 C",
            "CAMCO DL 1500 C",
            "CAMCO DL 3000 C",
            "CAMCO DL 600 C",
            "V-DOSC",
            "IF112",
            "MTD115",
            "DOSC",
            "MTD115/CO",
            "INCREMENTAL",
        ];
        $("#search_product").autocomplete({
            autoFocus: true,
            source: availableTags,
            appendTo: "#search_result_product",
            response: function (event, ui) {
                // ui.content is the array that's about to be sent to the response callback.
                if (ui.content.length === 0) {
                    $("#search_result_empty").text("No results for this search.");
                } else {
                    $("#search_result_empty").empty();
                }
            },
            select: function (event, ui) { // When the user click on a result
                if (ui.item.value.toLowerCase() == "sb10i") {
                    $('#search_qrcode').show();
                }
            }
        }).data("ui-autocomplete")._renderItem = function (ul, item) {
            let txt = String(item.value).replace(new RegExp(this.term, "gi"), "<b>$&</b>");
            return $("<li></li>")
                .data("item.autocomplete", item)
                .append(txt)
                .appendTo(ul);
        };
    }
    $('.check-compatibility').on('click', function(){
        let canvas = document.createElement('canvas');
        let gl;
        let debugInfo;
        let gpuCompatible;
        let osCompatible;

        try {
            gl = canvas.getContext('webgl2');
        } catch (e) {
        }

        const platformsCompatible = ['MacIntel', 'Win32'];
        let userAgent = window.navigator.userAgent;
        let platformOS = window.navigator.platform;

        if ( platformsCompatible.includes(platformOS)) {
            osCompatible = true;
        }
        else {
            osCompatible = false;
        }

        if (gl) {
            gpuCompatible = true;
        }
        else {
            gpuCompatible = false;
        }

        if ( osCompatible && gpuCompatible == true ) {
            $('.check-compatibility').after('<p>&#10003 &nbsp;&nbsp; Your system is compatible with this software.</p>');
        }
        else if ( osCompatible == true && gpuCompatible == false ) {
            $('.check-compatibility').after('<p>&#10003 &nbsp;&nbsp; Your operating system is compatible with this software.</p>');
            $('.check-compatibility').after('<p>&#10005 &nbsp;&nbsp; Your graphics card is not compatible with this software.</p>');
        }
        else if ( osCompatible == false && gpuCompatible == true ) {
            $('.check-compatibility').after('<p>&#10005 &nbsp;&nbsp; Your operating system is not compatible with this software.</p>');
            $('.check-compatibility').after('<p>&#10003 &nbsp;&nbsp; Your graphics card is compatible with this software.</p>');
        }
        else {
            $('.check-compatibility').after('<p>&#10005 &nbsp;&nbsp; Your system is not compatible with this software.</p>');
        }
        $(this).hide();
    });

    $('body.page-template-solutions-page .solutions-container .card-text').hover(function(){
        $(this).find('.description-container').slideToggle();
    });

    $('.products-template-solutions area').on('click', function(event) {
        var boxId = $(this).data('box');
        var infoBox = $('#' + boxId);

        // Hide all info boxes
        $('.info-box').hide();

        // Show the clicked info box
        var offset = $('.image-map').offset();
        var x = event.pageX - offset.left + 10;
        var y = event.pageY - offset.top + 10;
        infoBox.css({
            display: 'block',
            left: x + 'px',
            top: y + 'px'
        });
    });

    if ($('body.products-template-solutions .descriptions-carousel .glider').length > 0) {
        var descriptions_carousel = new Glider(document.querySelector('body.products-template-solutions .descriptions-carousel .glider'), {
            slidesToShow: 1,
            dots: '#dots',
            draggable: false,
            arrows: {
                prev: '.glider-prev',
                next: '.glider-next'
            }
        });

        var images_carousel = new Glider(document.querySelector('body.products-template-solutions .images-carousel .glider'), {
            slidesToShow: 1,
            slidesToScroll: 1,
            dots: '#dots',
            draggable: false,
            arrows: {
                prev: '.glider-prev',
                next: '.glider-next'
            },
            responsive: [
                {
                    // screens greater than >= 576px
                    breakpoint: 576,
                    settings: {
                        slidesToShow: 1.15,
                        slidesToScroll: 1,
                    }
                },{
                    // screens greater than >= 1024px
                    breakpoint: 1200,
                    settings: {
                        slidesToShow: 1.15,
                        slidesToScroll: 1,
                    }
                }
            ]
        });

        $('.detailed-carousel-nav button').on('click', function() {
            $('.detailed-carousel-nav button').removeClass('active');
            $(this).addClass('active');
            var slideIndex = $(this).data('slide');
            descriptions_carousel.scrollItem(slideIndex);
            images_carousel.scrollItem(slideIndex);
        });
    }

    if ($('body.products-template-solutions .bg-images-carousel .glider').length > 0) {
        new Glider(document.querySelector('body.products-template-solutions .bg-images-carousel .glider'), {
            slidesToShow: 1,
            slidesToScroll: 1,
            dots: '#dots',
            draggable: true,
            arrows: {
                prev: '.glider-prev',
                next: '.glider-next'
            },
            responsive: [
                {
                    // screens greater than >= 576px
                    breakpoint: 576,
                    settings: {
                        slidesToShow: 1.15,
                        slidesToScroll: 1,
                    }
                },{
                    // screens greater than >= 1024px
                    breakpoint: 1200,
                    settings: {
                        slidesToShow: 1.5,
                        slidesToScroll: 1,
                    }
                }
            ]
        });

    }
    // Components section button click handler
    $('body.products-template-solutions .components-section .component-button').on('click', function(){
        $(this).toggleClass('active');
        if ($(this).hasClass('active')) {
            $(this).children('.expand').html('&ndash;');
        } else {
            $(this).children('.expand').html('&plus;');
        }
        $(this).siblings('.component-description').slideToggle();
    });

    // Check for the existence of the cards carousel
    if ($('body.products-template-solutions .cards-carousel .glider').length > 0) {
        new Glider(document.querySelector('body.products-template-solutions .cards-carousel .glider'), {
            slidesToShow: 1.05,
            dots: '#dots',
            draggable: true,
            arrows: {
                prev: '.glider-prev',
                next: '.glider-next'
            },
            responsive: [
                {
                    // screens greater than >= 576px
                    breakpoint: 576,
                    settings: {
                        slidesToShow: 1.25,
                    }
                },
                {
                    // screens greater than >= 576px
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 1.75,
                    }
                },
                {
                    // screens greater than >= 1024px
                    breakpoint: 1200,
                    settings: {
                        slidesToShow: 2.25,
                    }
                }
            ]
        });
    }

    if ($('body.products-template-solutions .stories-selection .glider').length > 0) {
        new Glider(document.querySelector('body.products-template-solutions .stories-selection .glider'), {
            slidesToShow: 1.1,
            dots: '#dots',
            draggable: true,
            arrows: {
                prev: '.glider-prev',
                next: '.glider-next'
            },
            responsive: [
                {
                    breakpoint: 576,
                    settings: {
                        slidesToShow: 1.5
                    }
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 2.15
                    }
                },
                {
                    breakpoint: 1200,
                    settings: {
                        slidesToShow: 2.5
                    }
                },
                {
                    breakpoint: 1600,
                    settings: {
                        slidesToShow: 3.5
                    }
                }
            ]
        });
    }

    // Function to set initial radius of circles based on screen width
    function setInitialCircleRadius() {
        $('body.products-template-solutions .map-point circle').each(function() {
            var $circle = $(this);
            if ($(window).width() >= 900) {
                $circle.attr('r', 20);
            } else if ($(window).width() >= 768) {
                $circle.attr('r', 35);
            } else {
                $circle.attr('r', 75);
            }
        });
    }

    // Call the function when the DOM is loaded
    setInitialCircleRadius();

    $(window).on('resize', function() {
        setInitialCircleRadius();
    });


    // Function to position info box relative to circle
    function positionInfoBox($circle, $infoBox) {
        var circleCX = parseFloat($circle.attr('cx'));
        var circleCY = parseFloat($circle.attr('cy'));
        var circleR = parseFloat($circle.attr('r'));
        var infoBoxWidth = $infoBox.outerWidth();
        var infoBoxHeight = $infoBox.outerHeight();
        var windowWidth = $(window).width();
        var windowHeight = $(window).height();

        // Calculate desired position 30px away from the circle
        var infoBoxLeft = circleCX - infoBoxWidth / 2;
        var infoBoxTop = circleCY - circleR - infoBoxHeight - 30;

        // Adjust info box position to fit within viewport
        if (infoBoxLeft < 0) {
            infoBoxLeft = 20;
        } else if (infoBoxLeft + infoBoxWidth > windowWidth) {
            infoBoxLeft = windowWidth - infoBoxWidth - 20;
        }

        if (infoBoxTop < 0) {
            infoBoxTop = 20;
        } else if (infoBoxTop + infoBoxHeight > windowHeight) {
            infoBoxTop = windowHeight - infoBoxHeight - 20;
        }

        $infoBox.css({
            left: infoBoxLeft + 'px',
            top: infoBoxTop + 'px'
        });
    }

    $('.map-point').on('click', function(event) {
        event.preventDefault();
        var $circle = $(this).find('circle');
        var pointId = $(this).closest('.map-point-group').data('point-id');
        var $infoBox = $('#' + pointId + 'Box');
        var $togglePlus = $(this).find('.plus-toggle');


        if ($circle.hasClass('expanded')) {
            $('.plus-toggle').html('+');
            $togglePlus.removeClass('active');
            $circle.removeClass('expanded');
            $infoBox.removeClass('active');
            console.log('add the minus');
        } else {
            $('.map-point circle').removeClass('expanded');
            $('.info-box').removeClass('active');
            $circle.addClass('expanded');
            $infoBox.addClass('active');
            $('.plus-toggle').html('+');
            $('.plus-toggle').removeClass('active');
            $circle.siblings('.plus-toggle').html('&ndash;');
            $togglePlus.addClass('active');
            if ($(window).width() >= 800) {
                // Get the SVG container position and size
                var svgContainer = $('#svg-container');
                var svgContainerOffset = svgContainer.offset();
                var svgContainerWidth = svgContainer.width();
                var svgContainerHeight = svgContainer.height();

                // Calculate the scaled coordinates
                var viewBoxWidth = 1728;
                var viewBoxHeight = 1152;
                var circleCX = parseFloat($circle.attr('cx')) * (svgContainerWidth / viewBoxWidth);
                var circleCY = parseFloat($circle.attr('cy')) * (svgContainerHeight / viewBoxHeight);

                if ($(window).width() >= 1200) {
                    // Position the info box
                    $infoBox.css({
                        left: (circleCX + 20) + 'px', // Adjust as needed
                        top: (circleCY - 100) + 'px'   // Adjust as needed
                    });
                } else {
                    $infoBox.css({
                        left: (circleCX + 20) + 'px', // Adjust as needed
                        top: (circleCY - 50) + 'px'   // Adjust as needed
                    });
                }
            } else {
                $infoBox.css({
                    left: '5%',
                    top: 'auto'
                });
            }
        }
    });

    $(document).on('click', function(event) {
        if (!$(event.target).closest('.map-point, .info-box').length) {
            $('.map-point circle').removeClass('expanded');
            $('.info-box').removeClass('active');
            $('.plus-toggle').html('+');
            $('.plus-toggle').removeClass('active');
        }
    });

    if ($('body.products-template-solutions .comparison-section').length > 0) {
        var $divisor = $('#divisor');
        var $handle = $('#handle');
        var $slider = $('#slider');

        function moveDivisor() {
            var value = $slider.val();
            $handle.css('left', value + '%');
            $divisor.css('width', value + '%');
        }

        // Initialize the position of the divisor and handle
        moveDivisor();

        // Update the position when the slider value changes
        $slider.on('input', function() {
            console.log('i have moved');
            moveDivisor();
        });
    }

    if ($('body.products-template-solutions .fc-layout.youtube-embed').length > 0) {
        $('#solutionsYoutubePlayer').on('click', function () {
            $('#solutionsYoutubePlayer').fadeOut(200);
            $('.solutions-embed-container').fadeIn(800);
            $("#playVideo")[0].contentWindow.postMessage('{"event":"command","func":"playVideo"}', '*');
        });
    }

    if ( $('body').hasClass('l-isa-studio') ) {
        if ($('body.l-isa-studio .lisa-studio-awards-carousel .glider').length > 0) {
            new Glider(document.querySelector('body.l-isa-studio .lisa-studio-awards-carousel .glider'), {
                slidesToShow: 1,
                dots: '#awardsdots',
                draggable: true,
                arrows: {
                    prev: '.glider-prev',
                    next: '.glider-next'
                },
                responsive: [
                    {
                        // screens greater than >= 576px
                        breakpoint: 576,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 1,
                        }
                    },{
                        // screens greater than >= 1024px
                        breakpoint: 1200,
                        settings: {
                            slidesToShow: 4,
                            slidesToScroll: 1,
                        }
                    }
                ]
            });
        }
        if ($('body.l-isa-studio .lisa-studio-awards-carousel .glider').length > 0) {
            new Glider(document.querySelector('body.l-isa-studio .lisa-studio-reviews .glider'), {
                slidesToShow: 1,
                dots: '#reviewsdots',
                draggable: true,
                arrows: {
                    prev: '.glider-prev',
                    next: '.glider-next'
                },
                responsive: [
                    {
                        // screens greater than >= 576px
                        breakpoint: 576,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 1,
                        }
                    },{
                        // screens greater than >= 1024px
                        breakpoint: 1200,
                        settings: {
                            slidesToShow: 3,
                            slidesToScroll: 1,
                        }
                    }
                ]
            });
        }
    }

    if ($('body.page-template-subsidiaries .history-carousel-container .history-slides').length > 0) {
        $('.history-slides').slick({
            centerMode: true,
            centerPadding: "0px",
            slidesToShow: 2.8,
            slidesToScroll: 1,
            arrows: true,
            prevArrow: $('.prev'),
            nextArrow: $('.next'),
            dots: false,
            focusOnSelect: true,
            infinite: false,
            adaptiveHeight: true,
            responsive: [
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        dots: true,
                        arrows: false
                    }
                }
            ]
        });
    }
    $('body.page-template-subsidiaries .wp-block-social-link-anchor').attr('target', '_blank');
    function isScrolledIntoView(elem) {
        var docViewTop = $(window).scrollTop();
        var docViewBottom = docViewTop + $(window).height();

        var elemTop = $(elem).offset().top;
        var elemBottom = elemTop + $(elem).height();

        return ((elemBottom <= docViewBottom) && (elemTop >= docViewTop));
    }

    $(window).scroll(function () {
        $('.page-template-subsidiaries .wp-block-group .wp-block-columns').each(function () {
            if (isScrolledIntoView(this) === true) {
                $(this).addClass('visible');
            }
        });
    });

});